


































import { Component, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Action, Getter } from 'vuex-class';

@Component
export default class NavBar extends Vue {
  // @State
  // ready: boolean=false;

  // @State
  // address: string="-";

  @Getter
  private connectedToTestnet!: boolean;

  @Action
  connect!: ActionMethod;

  // openAccountSettings(): void {
  //   this.connect();
  // }

  // get formattedAddress(): string {
  //   return `${this.address.slice(0, 6)}...${this.address.slice(-4)}`;
  // }
}
