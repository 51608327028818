





import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import AccountButton from "@/components/AccountButton.vue";

@Component
export default class extends Vue {
  @Prop() readonly background!: string;
  @Prop() readonly color!: string;
  @Prop({ required: true }) readonly text!: string;
  @Prop() readonly width!: string;
  @Prop({ default: () => {} }) readonly action!: () => void;

  @State connectionStatus!: boolean;

  @Action connect!: ActionMethod;

  get style () {
    const style: any = { background: this.background, color: this.color, width: this.width };

    if (this.width) {
      style["padding-left"] = 0;
      style["padding-right"] = 0;
    }

    return style;
  }
}
