









import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import AccountButton from "@/components/AccountButton.vue";
import NotificationsButton from "@/components/NotificationsButton.vue";
import Network from "@/components/Network.vue";

@Component({
  components: { Network, AccountButton, NotificationsButton }
})
export default class PageHeader extends Vue {
  @Prop({ required: true }) readonly title!: string;

  @State connectionStatus!: boolean;

  @Action connect!: ActionMethod;
}
