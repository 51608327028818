










import { ethers } from "ethers";
import { Component, Vue } from "vue-property-decorator";
import FlatButton from "@/components/FlatButton.vue";

@Component({
  components: { FlatButton }
})
export default class extends Vue {
  $modal: any;
  async created() {
    if (!window.ethereum) {
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const { chainId } = await provider.getNetwork();

    if (chainId !== 888) {
      this.$modal.show("changeNetworkModal");
      this.swapToTestnet();
    }
  }

  async swapToTestnet() {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x378", // 888
          chainName: "Pinkman Testnet",
          nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
          rpcUrls: ["https://testnet.pinkman.finance/"]
        }
      ]
    });
  }
}
