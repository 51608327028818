








import { Component, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Action } from 'vuex-class';
import { ConnectionStatus, Network } from "@/store";

@Component
export default class extends Vue {
  @State network!: Network;

  get networkText(): string {
    switch (this.network) {
      case Network.UNDEFINED:
        return "Unsupported!"
      case Network.MAINNET:
        return "";
      case Network.LOCAL:
        return "Local";
      case Network.PINKMAN:
        return "Pinkman Testnet"
      default:
        return "Undefined";
    }
  }

  get networkClass() {
    switch (this.network) {
      case Network.UNDEFINED:
        return "unsupported"
      case Network.MAINNET:
        return "main";
      case Network.LOCAL:
        return "test";
      case Network.PINKMAN:
        return "test"
      default:
        return "unsupported";
    }
  }
}
