












import { Component, Vue, Watch } from "vue-property-decorator";
// import type { ActionMethod } from 'vuex';
// import { State, Action } from 'vuex-class';
import NavBar from "./components/NavBar.vue";
import NetworkSwithcModal from "./components/NetworkSwtichModal.vue";

@Component({
  components: {
    NavBar,
    NetworkSwithcModal
  }
})
export default class App extends Vue {
  private dark = true;
  $route: any;

  get cssVars() {
    return {
      "--bg-color": this.dark ? "#25252E" : "#ffffff",
      "--bg-color-alt": this.dark ? "#282932" : "#ffffff",
      "--nav-bar-color": this.dark ? "#25252E" : "#ff9999",
      "--nav-border": this.dark ? "1px solid #707070" : "none",
      "--text-color": this.dark ? "#ffffff" : "#25252E",
      "--text-color-alt": this.dark ? "#25252E" : "#ffffff"
    };
  }

  created() {
    this.setMetaData();
  }

  @Watch("$route")
  nameChanged(to: any) {
    this.setMetaData();
  }

  setMetaData() {
    if (this.$route.meta.title) {
      document.title = `Pinkman - ${this.$route.meta.title}`;
    } else {
      document.title = `Pinkman`;
    }
  }
}
