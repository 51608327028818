







import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import UnderContruction from "@/components/UnderContruction.vue";

@Component({
  components: { PageHeader, UnderContruction }
})
export default class Dashboard extends Vue {}
