import Vue from "vue";
// @ts-ignore
import App from "./App.vue";
import vmodal from "vue-js-modal";
// @ts-ignore
import VueFormulate from "@braid/vue-formulate";
import Toasted from "vue-toasted";
import router from "./router";
import store, { Network, PINKMAN_TESTNET_PROVIDER } from "./store";
import AsyncComputed from "vue-async-computed";
import { ethers } from "ethers";
import { Contracts } from "./utils/types";
import MainNetContracts from "@/config/mainnet/contracts.json";
import LocalContracts from "@/config/local/contracts.json";
import PinkmanTestnetContracts from "@/config/testnets/pinkman/contracts.json";

Vue.use(vmodal);
Vue.use(VueFormulate);
Vue.use(Toasted);
Vue.use(AsyncComputed);

Vue.config.productionTip = false;

// Setup
(async function() {
  // TODO: Add ready flag on which to stop showing loading screen and show content
  let provider = PINKMAN_TESTNET_PROVIDER;
  if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  }
  const { chainId } = await provider.getNetwork();

  let contracts: Contracts = MainNetContracts;
  let network: Network = Network.UNDEFINED;
  if (chainId === 1337) {
    // Local network
    network = Network.LOCAL;
    contracts = LocalContracts;
  } else if (chainId === 888) {
    // Pinkman Testnet
    network = Network.PINKMAN;
    contracts = PinkmanTestnetContracts;
  } else if (chainId === 1) {
    // Main network
    network = Network.MAINNET;
    contracts = MainNetContracts;
  } else {
    alert("Unsupported network...");
    return;
  }

  window.ethereum?.on("chainChanged", (chainId: string) => {
    location.reload();
  });

  // TODO: Handle account change

  store.commit("setContracts", contracts);
  store.commit("setNetwork", network);
})();

new Vue({
  // @ts-ignore
  router,
  store,
  render: h => h(App)
}).$mount("#app");
