





import { Component, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Action } from 'vuex-class';
import { ConnectionStatus } from "@/store";

@Component
export default class extends Vue {
  private notifications = [0];
}
