import { IInterestModel } from "@/contracts/IInterestModel";
import IInterestModelABI from "@/contracts/IInterestModel.json";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { Risk, tokenDecimals } from "./constants";
import { RAY, rayDiv, rayMul } from "./helpers";
import { PriceFetcher } from "./PriceFetcher";
import { getTokenSymbol } from "./tokens";
import { AssetSymbol, Contracts } from "./types";

// TODO: Pass contract and provider to constructor
export class Position {
  positionId: number;
  asset: AssetSymbol;
  amount: string;
  debt: { [key: string]: string };
  priceFetcher: PriceFetcher;
  inTolling: boolean;

  constructor({
    positionId,
    asset,
    amount,
    debt,
    priceFetcher,
    inTolling
  }: {
    positionId: number;
    asset: AssetSymbol;
    amount: string;
    debt: { [key: string]: string };
    priceFetcher: PriceFetcher;
    inTolling: boolean;
  }) {
    this.positionId = positionId;
    this.asset = asset;
    this.amount = amount;
    this.debt = debt;
    this.priceFetcher = priceFetcher;
    this.inTolling = inTolling;
  }

  get weiToUsd() {
    // TODO: Get this value properly
    return ethers.utils.parseUnits("1");
  }

  async valueInWeiAsRay() {
    const assetAmount = ethers.utils.parseUnits(
      this.amount,
      tokenDecimals[this.asset]
    );
    const price = await this.priceFetcher.getPriceOfUnitInWeiAsRay(this.asset);

    const value = assetAmount.mul(price);

    return value;
  }

  async valueInUsdAsRay() {
    return (await this.valueInWeiAsRay()).div(this.weiToUsd);
  }

  async debtValueInWeiAsRay(
    contracts: Contracts,
    provider: ethers.providers.Provider
  ) {
    let debtValue = ethers.BigNumber.from(0);

    const leveragePoolAddress = contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      provider
    ) as ILeveragePool;

    for (const reserve in this.debt) {
      if (Object.prototype.hasOwnProperty.call(this.debt, reserve)) {
        const reserveData = await leveragePool.getReserveData(reserve);

        const underlyingAsset = getTokenSymbol(
          reserveData.underlyingToken,
          contracts
        ) as AssetSymbol;

        if (underlyingAsset === undefined) {
          throw new Error(
            `Failed to get symbol for token ${reserveData.underlyingToken}`
          );
        }

        const amount = ethers.utils.parseUnits(
          this.debt[reserve],
          tokenDecimals[underlyingAsset]
        );

        debtValue = debtValue.add(
          (
            await new PriceFetcher(
              contracts,
              provider
            ).getPriceOfUnitInWeiAsRay(underlyingAsset)
          ).mul(amount)
        );
      }
    }

    return debtValue;
  }

  async debtValueInUsdAsRay(
    contracts: Contracts,
    provider: ethers.providers.Provider
  ) {
    return (await this.debtValueInWeiAsRay(contracts, provider)).div(
      this.weiToUsd
    );
  }

  async netValue(contracts: Contracts, provider: ethers.providers.Provider) {
    const positionValue = await this.valueInWeiAsRay();
    const debtValue = await this.debtValueInWeiAsRay(contracts, provider);

    if (debtValue.gte(positionValue)) {
      return ethers.BigNumber.from(0);
    }

    const netValue = positionValue.sub(debtValue);
    return netValue;
  }

  async leverageMultiplier(
    contracts: Contracts,
    provider: ethers.providers.Provider
  ) {
    const positionValue = await this.valueInWeiAsRay();
    const netValue = await this.netValue(contracts, provider);

    return rayDiv(positionValue, netValue);
  }

  async maxLeverage() {
    return RAY.mul(100);
  }

  async riskLevel(contracts: Contracts, provider: ethers.providers.Provider) {
    const leverageMultiplier = await this.leverageMultiplier(
      contracts,
      provider
    );
    if (leverageMultiplier.eq(0)) {
      return Risk.LIQUIDATABLE;
    }

    const maxLeverage = await this.maxLeverage();

    const tollableLevel = ethers.BigNumber.from(150).mul(RAY);
    if (leverageMultiplier.gt(tollableLevel)) {
      return Risk.TOLLABLE;
    }

    if (leverageMultiplier.gt(maxLeverage)) {
      return Risk.AT_RISK;
    }

    return Risk.SAFE;
  }

  async leverageUtilization(
    contracts: Contracts,
    provider: ethers.providers.Provider
  ) {
    return rayDiv(
      await this.leverageMultiplier(contracts, provider),
      await this.maxLeverage()
    );
  }

  async debtInAsset(contracts: Contracts, provider: ethers.providers.Provider) {
    const debtInAsset: { [key: string]: string } = {};

    const leveragePoolAddress = contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      provider
    ) as ILeveragePool;

    for (const reserve in this.debt) {
      if (Object.prototype.hasOwnProperty.call(this.debt, reserve)) {
        const debtAmount = this.debt[reserve];
        const reserveData = await leveragePool.getReserveData(reserve);
        const underlyingAsset = reserveData.underlyingToken;

        const underlyingTokenSymbol = getTokenSymbol(
          underlyingAsset,
          contracts
        ) as AssetSymbol;

        if (underlyingTokenSymbol === undefined) {
          throw new Error("Failed to get token symbol from address");
        }

        debtInAsset[underlyingTokenSymbol] = debtAmount;
      }
    }

    return debtInAsset;
  }

  async debtAprs(contracts: Contracts, provider: ethers.providers.Provider) {
    const debtAprs: { [key: string]: BigNumber } = {};

    const leveragePoolAddress = contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      provider
    ) as ILeveragePool;

    for (const reserve in this.debt) {
      if (Object.prototype.hasOwnProperty.call(this.debt, reserve)) {
        const debtAmount = this.debt[reserve];
        const reserveData = await leveragePool.getReserveData(reserve);

        const totalDebt = reserveData.cached.totalDebt;
        const totalSaving = reserveData.cached.totalSaving;
        const interestRateModelAddress = reserveData.interestModel;
        const underlyingAsset = reserveData.underlyingToken;

        const interestRateModel = new ethers.Contract(
          interestRateModelAddress,
          IInterestModelABI,
          provider
        ) as IInterestModel;

        const [, borrowRate] = await interestRateModel.getInterestRates(
          underlyingAsset,
          totalDebt,
          totalSaving
        );

        const underlyingTokenSymbol = getTokenSymbol(
          underlyingAsset,
          contracts
        ) as AssetSymbol;

        if (underlyingTokenSymbol === undefined) {
          throw new Error("Failed to get token symbol from address");
        }

        debtAprs[underlyingTokenSymbol] = borrowRate;
      }
    }

    return debtAprs;
  }

  async avgDebtApr(contracts: Contracts, provider: ethers.providers.Provider) {
    const debtValue = await this.debtValueInWeiAsRay(contracts, provider);
    let weightedAprSum = ethers.BigNumber.from(0);
    const debtAprs = await this.debtAprs(contracts, provider);

    const leveragePoolAddress = contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      provider
    ) as ILeveragePool;

    for (const reserve in this.debt) {
      if (Object.prototype.hasOwnProperty.call(this.debt, reserve)) {
        const reserveData = await leveragePool.getReserveData(reserve);
        const underlyingAsset = reserveData.underlyingToken;

        const underlyingTokenSymbol = getTokenSymbol(
          underlyingAsset,
          contracts
        ) as AssetSymbol;

        if (underlyingTokenSymbol === undefined) {
          throw new Error("Failed to get token symbol from address");
        }

        const debtAmount = ethers.utils.parseUnits(
          this.debt[reserve],
          tokenDecimals[underlyingTokenSymbol]
        );
        const price = await new PriceFetcher(
          contracts,
          provider
        ).getPriceOfUnitInWeiAsRay(underlyingTokenSymbol);
        const debtValue = debtAmount.mul(price);

        weightedAprSum = weightedAprSum.add(
          debtAprs[underlyingTokenSymbol].mul(debtValue)
        );
      }
    }

    return weightedAprSum.div(debtValue);
  }

  // TODO: Figure out how to get properly
  async underlyingApy() {
    // 7.563%
    return RAY.mul(7563).div(100000);
  }

  async effectiveApy(
    contracts: Contracts,
    provider: ethers.providers.Provider
  ) {
    const underlyingApy = await this.underlyingApy();

    const currentValue = await this.valueInWeiAsRay();
    const valueAfterAYear = rayMul(currentValue, RAY.add(underlyingApy));

    let allDebtToRepayValue = ethers.BigNumber.from(0);

    const leveragePoolAddress = contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      provider
    ) as ILeveragePool;

    for (const reserve in this.debt) {
      if (Object.prototype.hasOwnProperty.call(this.debt, reserve)) {
        const debtAmount = this.debt[reserve];
        const reserveData = await leveragePool.getReserveData(reserve);

        const totalDebt = reserveData.cached.totalDebt;
        const totalSaving = reserveData.cached.totalSaving;
        const interestRateModelAddress = reserveData.interestModel;
        const underlyingAsset = reserveData.underlyingToken;

        const interestRateModel = new ethers.Contract(
          interestRateModelAddress,
          IInterestModelABI,
          provider
        ) as IInterestModel;

        const [, borrowRate] = await interestRateModel.getInterestRates(
          underlyingAsset,
          totalDebt,
          totalSaving
        );

        const underlyingTokenSymbol = getTokenSymbol(
          underlyingAsset,
          contracts
        ) as AssetSymbol;

        if (underlyingTokenSymbol === undefined) {
          throw new Error("Failed to get token symbol from address");
        }

        const debtToRepayInRay = RAY.add(borrowRate).mul(
          ethers.utils.parseUnits(
            debtAmount,
            tokenDecimals[underlyingTokenSymbol as AssetSymbol]
          )
        );

        const debtPrice = await new PriceFetcher(
          contracts,
          provider
        ).getPriceOfUnitInWeiAsRay(underlyingTokenSymbol);
        const debtToRepayValue = rayMul(debtToRepayInRay, debtPrice);
        allDebtToRepayValue = allDebtToRepayValue.add(debtToRepayValue);
      }
    }

    const currentDebtValue = await this.debtValueInWeiAsRay(
      contracts,
      provider
    );
    const suppliedValue = currentValue.sub(currentDebtValue);
    const finalValue = valueAfterAYear.sub(allDebtToRepayValue);

    const effectiveApy = rayDiv(finalValue, suppliedValue).sub(RAY);

    return effectiveApy;
  }

  async isBeingTolled() {}
}
