import { ethers } from "ethers";

export function formatBigNumberString(number: string) {
  const integer = number.split(".")[0];

  const precision = 3;

  // IMPORTANT: Order from biggest to smallest
  const formatters = [
    { magnitude: 9, symbol: "B" },
    { magnitude: 6, symbol: "M" },
    { magnitude: 3, symbol: "K" }
  ];

  for (const { magnitude, symbol } of formatters) {
    if (integer.length <= magnitude) {
      continue;
    }

    const magnitudePlace = integer.length - magnitude;
    const units = integer.slice(0, magnitudePlace);
    if (magnitudePlace >= precision) {
      return `${units}${symbol}`;
    }
    const rest = integer.slice(magnitudePlace, precision);
    return `${units}.${rest}${symbol}`;
  }

  // Handle small case
  if (integer.length === 3 || number.split(".")[1] === "0") {
    return integer;
  }

  return `${integer}.${number.split(".")[1].slice(0, 3 - integer.length)}`;
}

export function formatRay(_percentage: ethers.BigNumber, precision: number) {
  if (precision > 27) {
    throw new Error("Max precision is 27");
  }

  const percentage = _percentage.div(RAY);
  const rest = _percentage.mod(RAY);
  let formattedRest = "";
  for (let index = 0; index < 27 - rest.toString().length; index++) {
    formattedRest += "0";
  }
  formattedRest += rest.toString();
  formattedRest = formattedRest.slice(0, precision);

  return `${percentage.toString()}.${formattedRest}`;
}

export function formatRayPercentage(
  _percentage: ethers.BigNumber,
  precision: number
) {
  if (precision > 27) {
    throw new Error("Max precision is 27");
  }

  const percentage = _percentage.mul(100).div(RAY);
  const rest = _percentage.mul(100).mod(RAY);
  let formattedRest = "";
  for (let index = 0; index < 27 - rest.toString().length; index++) {
    formattedRest += "0";
  }
  formattedRest += rest.toString();
  formattedRest = formattedRest.slice(0, precision);

  return `${percentage.toString()}.${formattedRest}`;
}

export const RAY = ethers.BigNumber.from(10).pow(27);
const halfRAY = RAY.div(2);

export function rayMul(a: ethers.BigNumber, b: ethers.BigNumber) {
  if (a.eq(0) || b.eq(0)) {
    return ethers.BigNumber.from(0);
  }

  return a
    .mul(b)
    .add(halfRAY)
    .div(RAY);
}

export function rayDiv(a: ethers.BigNumber, b: ethers.BigNumber) {
  if (b.eq(0)) {
    throw new Error("Division by 0");
  }

  const halfB = b.div(2);

  return a
    .mul(RAY)
    .add(halfB)
    .div(b);
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
