import { IAssetBank } from "@/contracts/IAssetBank";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import IAssetBankABI from "@/contracts/IAssetBank.json";
import { BigNumberish, Contract, ethers } from "ethers";
import { tokenDecimals } from "./constants";
import { IDebtToken } from "@/contracts/IDebtToken";
import DebtTokenABI from "@/contracts/DebtToken.json";
import { AssetSymbol, Contracts } from "./types";
import { getTokenSymbol } from "./tokens";
import { Position } from "./Position";
import { PriceFetcher } from "./PriceFetcher";

// TODO: Cache fetched positions

export class PositionFetcher {
  private contracts: Contracts;
  private provider: ethers.providers.Provider;
  private leveragePool: ILeveragePool;

  constructor(contracts: Contracts, provider: ethers.providers.Provider) {
    this.contracts = contracts;
    this.provider = provider;

    const leveragePoolAddress = contracts.LeveragePool;

    this.leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      provider
    ) as ILeveragePool;
  }

  async fetchPosition(
    positionId: number,
    priceFetcher: PriceFetcher
  ): Promise<Position> {
    const rawPosition = await this.leveragePool.getPosition(positionId);

    const assetBankAddress = rawPosition.assetBank;

    const assetBank = new ethers.Contract(
      assetBankAddress,
      IAssetBankABI,
      this.provider
    ) as IAssetBank;

    const collAmount = await assetBank.collateralBalanceOf(positionId);
    const collToken = await assetBank.underlyingCollateralToken();
    const tokenSymbol = getTokenSymbol(
      collToken,
      this.contracts
    ) as AssetSymbol;

    if (tokenSymbol === undefined) {
      throw new Error(`Token symbol not found for address ${collToken}`);
    }

    let debtMap = rawPosition.debtMap;

    const debtReserveIndexes = [];
    let i = 0;
    while (!debtMap.eq(0)) {
      if (debtMap.mask(1).eq(1)) {
        debtReserveIndexes.push(i);
      }

      debtMap = debtMap.shr(1);
      i++;
    }

    const debt: { [key: string]: string } = {};

    // Addresses of the overlying tokens
    const reserveAddresses = await this.leveragePool.getReserveAddresses();
    for (const reserveIndex of debtReserveIndexes) {
      const reserveAddress = reserveAddresses[reserveIndex];
      const reserveData = await this.leveragePool.getReserveData(
        reserveAddress
      );

      const debtToken = new ethers.Contract(
        reserveData.debtToken,
        DebtTokenABI,
        this.provider
      ) as IDebtToken;

      const debtBalance = await debtToken.balanceOf(positionId);

      const debtTokenSymbol = await debtToken.symbol();
      debt[reserveAddress] = ethers.utils.formatUnits(
        debtBalance,
        (tokenDecimals as any)[debtTokenSymbol]
      );
    }

    const position: Position = new Position({
      positionId,
      asset: tokenSymbol,
      amount: ethers.utils.formatUnits(collAmount, tokenDecimals[tokenSymbol]),
      debt,
      priceFetcher,
      inTolling: rawPosition.inTolling
    });

    return position;
  }
}
