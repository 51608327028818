import { AssetMapping, AssetSymbol } from "./types";

export const tokenDecimals = {
  DAI: 18,
  USDC: 6,
  USDT: 6,
  "3CRV": 18,
  "paDAI-debt": 18,
  "paUSDT-debt": 6,
  "paUSDC-debt": 6
};

export const tokenSymbolsList: AssetSymbol[] = ["DAI", "USDC", "USDT", "3CRV"];

export const debtTokens: AssetMapping<string[]> = {
  DAI: ["paDAI-debt"],
  USDT: ["paUSDT-debt"],
  USDC: ["paUSDC-debt"],
  "3CRV": []
};

export enum Risk {
  LIQUIDATABLE = "liquidatable",
  TOLLABLE = "tollable",
  AT_RISK = "atRisk",
  SAFE = "safe"
}
