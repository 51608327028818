/* eslint-disable no-underscore-dangle */
import Vue from "vue";
import Vuex from "vuex";

import { ethers, Transaction } from "ethers";
import { AssetSymbol, Contracts } from "@/utils/types";

import MainNetContracts from "@/config/mainnet/contracts.json";
import LocalContracts from "@/config/local/contracts.json";
import PinkmanTestnetContracts from "@/config/testnets/pinkman/contracts.json";
import {
  baseTokenUnitsToDecimal,
  formatETHInRay,
  getTokenAddress
} from "@/utils/tokens";
import { OverlyingToken } from "@/contracts/OverlyingToken";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import { allAssets, reserveUnderlyingAssets } from "@/config/assets";
import IERC20ABI from "@/contracts/IERC20.json";
import { IERC20 } from "@/contracts/IERC20";
import OverlyingTokenABI from "@/contracts/OverlyingToken.json";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import IBaseOracleABI from "@/contracts/IBaseOracle.json";
import { tokenDecimals, tokenSymbolsList } from "@/utils/constants";
import { IPriceOracle } from "@/contracts/IPriceOracle";
import { IBaseOracle } from "@/contracts/IBaseOracle";
import { IAssetBank } from "@/contracts/IAssetBank";
import IAssetBankABI from "@/contracts/IAssetBank.json";
import DebtTokenABI from "@/contracts/DebtToken.json";
import { IDebtToken } from "@/contracts/IDebtToken";
import { Position } from "@/utils/Position";
import { PriceFetcher } from "@/utils/PriceFetcher";

Vue.use(Vuex);

export enum ConnectionStatus {
  NOT_CONNECTED,
  CONNECTING,
  CONNECTED
}

export enum Network {
  UNDEFINED,
  MAINNET,
  LOCAL,
  PINKMAN
}

export const PINKMAN_TESTNET_PROVIDER = new ethers.providers.JsonRpcProvider(
  "https://testnet.pinkman.finance",
  { name: "Pinkman Testnet", chainId: 888 }
);

// TODO: Test when metamask is not installed
let provider = PINKMAN_TESTNET_PROVIDER;
let contracts: Contracts = PinkmanTestnetContracts;
if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
}

function getPendingAllowanceUpdateTxsFromLocalStorage() {
  const rawPendingAllowanceUpdateTxs = window.localStorage.getItem(
    "pendingAllowanceUpdateTxs"
  );
  if (
    rawPendingAllowanceUpdateTxs === null ||
    rawPendingAllowanceUpdateTxs === undefined ||
    rawPendingAllowanceUpdateTxs === "undefined"
  ) {
    return {};
  }

  const pendingAllowanceUpdateTxs = JSON.parse(rawPendingAllowanceUpdateTxs);

  return pendingAllowanceUpdateTxs;
}

declare global {
  interface Window {
    ethereum: any;
  }
}

interface RootState {
  connectionStatus: ConnectionStatus;
  network: Network;
  provider: ethers.providers.Provider;
  signer: ethers.Signer | undefined;
  address: string;
  connectionCallbacks: Array<() => void>;
  contracts: Contracts;
  depositedBalances: { [key: string]: string };
  tokenBalances: { [key: string]: string };
  positions: Position[]; // user positions
  prices: { [key: string]: string };
  pendingAllowanceUpdateTxs: any;
  allowances: { [key: string]: string };
}

const store = new Vuex.Store<RootState>({
  state: {
    connectionStatus: ConnectionStatus.NOT_CONNECTED,
    network: Network.UNDEFINED,
    provider: provider,
    signer: undefined,
    address: "0x0000000000000000000000000000000000000000",
    connectionCallbacks: [],
    contracts: contracts,
    depositedBalances: {},
    tokenBalances: {},
    positions: [],
    prices: {},
    allowances: {},
    pendingAllowanceUpdateTxs: getPendingAllowanceUpdateTxsFromLocalStorage()
  },
  mutations: {
    setProvider(state, provider) {
      state.provider = provider;
    },
    setSigner(state, signer) {
      state.signer = signer;
    },
    setConnectionStatus(state, connectionStatus: ConnectionStatus) {
      state.connectionStatus = connectionStatus;
    },
    setAddress(state, address: string) {
      state.address = address;
    },
    addConnectionCallback(state, callback) {
      state.connectionCallbacks.push(callback);
    },
    clearConnectionCallback(state) {
      state.connectionCallbacks = [];
    },
    setContracts(state, contracts) {
      state.contracts = contracts;
    },
    setDepositedBalance(state, { token, balance }) {
      Vue.set(state.depositedBalances, token, balance);
    },
    setTokenBalance(state, { token, balance }) {
      Vue.set(state.tokenBalances, token, balance);
    },
    addPosition(state, position) {
      Vue.set(state.positions, state.positions.length, position);
    },
    clearPositions(state) {
      state.positions = [];
    },
    setPrice(state, { asset, price }) {
      Vue.set(state.prices, asset, price);
    },
    setNetwork(state, network) {
      state.network = network;
    },
    setPendingAllowanceUpdateTxs(state, { asset, txs }) {
      Vue.set(state.pendingAllowanceUpdateTxs, asset, txs);
      state.pendingAllowanceUpdateTxs[asset] = txs;
      window.localStorage.setItem(
        "pendingAllowanceUpdateTxs",
        JSON.stringify(state.pendingAllowanceUpdateTxs)
      );
    },
    setAllowance(state, { asset, allowance }) {
      console.log("Setting allowance for ", asset, allowance);
      Vue.set(state.allowances, asset, allowance);
    }
  },
  getters: {
    isConnected(state) {
      return state.connectionStatus === ConnectionStatus.CONNECTED;
    },
    connectedToTestnet(state) {
      return (
        state.network === Network.LOCAL || state.network === Network.PINKMAN
      );
    }
  },
  actions: {
    async connect({ commit, dispatch }) {
      commit("setConnectionStatus", ConnectionStatus.CONNECTING);

      if (typeof window.ethereum === "undefined") {
        alert("Please install MetaMask!");
        commit("setConnectionStatus", ConnectionStatus.NOT_CONNECTED);
        return;
      }

      if (!window.ethereum.isMetaMask) {
        console.warn("Not using metamask...");
      }

      let provider = PINKMAN_TESTNET_PROVIDER;
      if (window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }
      const { chainId } = await provider.getNetwork();

      let contracts: Contracts = MainNetContracts;
      if (chainId === 1337) {
        // Local network
        commit("setNetwork", Network.LOCAL);
        contracts = LocalContracts;
      } else if (chainId === 888) {
        // Pinkman Testnet
        commit("setNetwork", Network.PINKMAN);
        contracts = PinkmanTestnetContracts;
      } else if (chainId === 1) {
        // Main network
        commit("setNetwork", Network.MAINNET);
        contracts = MainNetContracts;
      } else {
        alert("Unsupported network...");
        return;
      }

      commit("setContracts", contracts);

      commit("setProvider", provider);

      await dispatch("setupUser", { provider, contracts });

      window.ethereum.on("accountsChanged", (newAccounts: Array<string>) => {
        dispatch("setupUser", { provider });
      });

      await dispatch("setPrices", { provider, contracts });

      await dispatch("handlePendingTxs", { provider });

      // TODO: Set listeners for user balance events update

      /**********************************************************/
      /* Handle chain (network) and chainChanged (per EIP-1193) */
      /**********************************************************/

      // const chainId = await ethereum.request({ method: 'eth_chainId' });
      // const { chainId } = await provider.getNetwork();

      // TODO: Get this to work...
      provider.on("network", handleChainChanged);
      function handleChainChanged(_chainId: number, _oldChainId: number) {
        // Reloading the page
        window.location.reload();
      }

      // Keep this at the end of all updates
      commit("setConnectionStatus", ConnectionStatus.CONNECTED);
      await dispatch("runConnectionCallbacks");
    },

    async handlePendingTxs({ state, commit, dispatch }, { provider }) {
      for (const asset in state.pendingAllowanceUpdateTxs) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.pendingAllowanceUpdateTxs,
            asset
          )
        ) {
          const txs: Transaction[] = state.pendingAllowanceUpdateTxs[asset];
          const pendingTxs: Transaction[] = [];
          for (const tx of txs) {
            const txReceipt = await provider.getTransactionReceipt(
              tx.hash ?? ""
            );
            if (!txReceipt?.blockNumber) {
              pendingTxs.push(tx);
            }
          }

          commit("setPendingAllowanceUpdateTxs", { asset, txs: pendingTxs });
        }
      }
    },

    async setupUser({ commit, dispatch }, { provider, contracts }) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      const address = accounts[0];

      const signer = provider.getSigner();

      commit("setAddress", address);
      commit("setSigner", signer);
      await dispatch("setBalances", { provider, address, contracts });
      await dispatch("setPositions", { provider, address, contracts });
      await dispatch("setAllowances", { provider, address, contracts });
    },

    // set the user's deposited and underlying balances
    async setBalances({ commit, dispatch }, { provider, address, contracts }) {
      const leveragePoolAddress = contracts.LeveragePool;

      const leveragePool = new ethers.Contract(
        leveragePoolAddress,
        ILeveragePoolABI,
        provider
      ) as ILeveragePool;

      // Deposited balances
      for (const asset of reserveUnderlyingAssets) {
        const tokenAddress = contracts[asset];
        const reserves = await leveragePool.getReserveForToken(tokenAddress);

        if (reserves.length === 0) {
          console.warn("No reserves for asset", asset);
          return;
        }

        if (reserves.length > 1) {
          console.warn("More than 1 reserve for asset", asset);
        }

        const updateDepositedBalances = async () => {
          const overlyingToken = new ethers.Contract(
            reserves[0],
            OverlyingTokenABI,
            provider
          ) as OverlyingToken;

          const baseUnitBalance = await overlyingToken.balanceOf(address);
          const decimalBalance = baseTokenUnitsToDecimal(
            asset,
            baseUnitBalance
          );
          commit("setDepositedBalance", {
            token: asset,
            balance: decimalBalance
          });
        };

        // List all token transfers *from* address
        const fromFilter = {
          address: contracts[asset],
          fromBlock: 0, // TODO: Set current block
          topics: [
            // the name of the event, parentheses containing the data type of each event, no spaces
            ethers.utils.id("Transfer(address,address,uint256)"),
            ethers.utils.hexZeroPad(address, 32)
          ]
        };

        // List all token transfers *to* address
        const toFilter = {
          address: contracts[asset],
          fromBlock: 0, // TODO: Set current block
          topics: [
            // the name of the event, parentheses containing the data type of each event, no spaces
            ethers.utils.id("Transfer(address,address,uint256)"),
            null,
            ethers.utils.hexZeroPad(address, 32)
          ]
        };

        provider.on(fromFilter, async (event: any) => {
          // Sent balance
          await updateDepositedBalances();
        });

        provider.on(toFilter, async (event: any) => {
          // Received balance
          await updateDepositedBalances();
        });

        await updateDepositedBalances();
      }

      // Underlying balances
      for (const asset of reserveUnderlyingAssets) {
        const updateBalance = async () => {
          const tokenAddress = contracts[asset];
          const token = new ethers.Contract(
            tokenAddress,
            IERC20ABI,
            provider
          ) as IERC20;
          const baseUnitBalance = await token.balanceOf(address);
          const decimalBalance = baseTokenUnitsToDecimal(
            asset,
            baseUnitBalance
          );
          commit("setTokenBalance", {
            token: asset,
            balance: decimalBalance
          });
        };

        // List all token transfers *from* address
        const fromFilter = {
          address: contracts[asset],
          fromBlock: 0, // TODO: Set current block
          topics: [
            // the name of the event, parentheses containing the data type of each event, no spaces
            ethers.utils.id("Transfer(address,address,uint256)"),
            ethers.utils.hexZeroPad(address, 32)
          ]
        };

        // List all token transfers *to* address
        const toFilter = {
          address: contracts[asset],
          fromBlock: 0, // TODO: Set current block
          topics: [
            // the name of the event, parentheses containing the data type of each event, no spaces
            ethers.utils.id("Transfer(address,address,uint256)"),
            null,
            ethers.utils.hexZeroPad(address, 32)
          ]
        };

        provider.on(fromFilter, async (event: any) => {
          // Sent balance
          console.log("Sent balance event for", asset, event);
          await updateBalance();
        });

        provider.on(toFilter, async (event: any) => {
          // Received balance
          console.log("Received balance event for", asset, event);
          await updateBalance();
        });

        await updateBalance();
      }
    },

    async setPositions({ commit, dispatch }, { provider, address, contracts }) {
      commit("clearPositions");

      const addressToSymbol: { [key: string]: AssetSymbol } = {};
      for (const name in contracts) {
        if ((tokenSymbolsList as string[]).includes(name)) {
          addressToSymbol[contracts[name]] = name as AssetSymbol;
        }
      }

      const leveragePoolAddress = contracts.LeveragePool;

      const leveragePool = new ethers.Contract(
        leveragePoolAddress,
        ILeveragePoolABI,
        provider
      ) as ILeveragePool;

      const positionIds = await leveragePool.getUserPositions(address);

      // // List all token transfers *from* address
      // const newPositionFilter = {
      //   address: contracts.PositionManager,
      //   fromBlock: 0, // TODO: Set current block
      //   topics: [
      //     // the name of the event, parentheses containing the data type of each event, no spaces
      //     ethers.utils.id("CreatedPosition(address,uint256)"),
      //     ethers.utils.hexZeroPad(address, 32)
      //   ]
      // };

      // provider.on(newPositionFilter, async (event: any) => {
      //   // New position created
      //   console.log("New positions created!", event);
      // });

      const priceFetcher = new PriceFetcher(contracts, provider);

      for (const positionId of positionIds) {
        const rawPosition = await leveragePool.getPosition(positionId);

        const assetBankAddress = rawPosition.assetBank;

        const assetBank = new ethers.Contract(
          assetBankAddress,
          IAssetBankABI,
          provider
        ) as IAssetBank;

        const collAmount = await assetBank.collateralBalanceOf(positionId);
        const collToken = await assetBank.underlyingCollateralToken();
        const tokenSymbol = addressToSymbol[collToken];

        let debtMap = rawPosition.debtMap;

        const debtReserveIndexes = [];
        let i = 0;
        while (!debtMap.eq(0)) {
          if (debtMap.mask(1).eq(1)) {
            debtReserveIndexes.push(i);
          }

          debtMap = debtMap.shr(1);
          i++;
        }

        const debt: { [key: string]: string } = {};

        // Addresses of the overlying tokens
        const reserveAddresses = await leveragePool.getReserveAddresses();
        for (const reserveIndex of debtReserveIndexes) {
          const reserveAddress = reserveAddresses[reserveIndex];
          const reserveData = await leveragePool.getReserveData(reserveAddress);

          const debtToken = new ethers.Contract(
            reserveData.debtToken,
            DebtTokenABI,
            provider
          ) as IDebtToken;

          const debtBalance = await debtToken.balanceOf(positionId);

          const debtTokenSymbol = await debtToken.symbol();
          debt[reserveAddress] = ethers.utils.formatUnits(
            debtBalance,
            (tokenDecimals as any)[debtTokenSymbol]
          );
        }

        const position: Position = new Position({
          positionId: positionId.toNumber(),
          asset: tokenSymbol,
          amount: ethers.utils.formatUnits(
            collAmount,
            tokenDecimals[tokenSymbol]
          ),
          debt,
          priceFetcher,
          inTolling: rawPosition.inTolling
        });

        // TODO: Replace all this with the positions fetcher
        commit("addPosition", position);
      }
    },

    async setPrices({ commit }, { provider, contracts }) {
      const priceOracle = new ethers.Contract(
        contracts.PriceOracle,
        IBaseOracleABI,
        provider
      ) as IBaseOracle;

      for (const asset of allAssets) {
        const address = getTokenAddress(asset, contracts);
        const price = formatETHInRay(
          await priceOracle.getETHPricePerTokenInRay(address)
        );

        commit("setPrice", { asset, price });
      }
    },

    async setAllowances(
      { state, getters, commit, dispatch },
      { provider, address, contracts }
    ) {
      const leveragePoolAddress = contracts.LeveragePool;
      const leveragePool = new ethers.Contract(
        leveragePoolAddress,
        ILeveragePoolABI,
        provider
      ) as ILeveragePool;

      for (const asset of allAssets) {
        const tokenAddress = contracts[asset];
        const tokenContract = new ethers.Contract(
          tokenAddress,
          IERC20ABI,
          provider
        ) as IERC20;

        const reserves = await leveragePool.getReserveForToken(
          contracts[asset]
        );

        if (reserves.length === 0) {
          console.warn("No reserves for asset", asset);
          return;
        }

        if (reserves.length > 1) {
          console.warn("More than 1 reserve for asset", asset);
        }

        const overlyingTokenAddress = reserves[0];

        const updateAllowance = async () => {
          const allowance = await tokenContract.allowance(
            address,
            overlyingTokenAddress
          );

          commit("setAllowance", {
            asset,
            allowance: baseTokenUnitsToDecimal(asset, allowance)
          });
        };

        // Allowance updates
        // event Approval(address indexed owner, address indexed spender, uint256 value);
        const filter = {
          address: contracts[asset],
          fromBlock: 0, // TODO: Set current block
          topics: [
            ethers.utils.id("Approval(address,address,uint256)"),
            ethers.utils.hexZeroPad(address, 32), // owner
            ethers.utils.hexZeroPad(overlyingTokenAddress, 32) // spender
          ]
        };

        // List all token transfers *from* address
        const fromFilter = {
          address: contracts[asset],
          fromBlock: 0, // TODO: Set current block
          topics: [
            // the name of the event, parentheses containing the data type of each event, no spaces
            ethers.utils.id("Transfer(address,address,uint256)"),
            ethers.utils.hexZeroPad(address, 32)
          ]
        };

        provider.on(filter, async (event: any) => {
          await updateAllowance();
          await dispatch("handlePendingTxs", { provider });
        });

        provider.on(fromFilter, async (event: any) => {
          await updateAllowance();
        });

        await updateAllowance();
      }
    },

    registerConnectionCallback({ state, getters, commit }, callback) {
      if (getters.isConnected) {
        callback();
      } else {
        commit("addConnectionCallback", callback);
      }
    },

    runConnectionCallbacks({ state, commit }) {
      for (const callback of state.connectionCallbacks) {
        callback();
      }

      commit("clearConnectionCallback");
    }
  },
  modules: {}
});

// /***********************************************************/
// /* Handle user accounts and accountsChanged (per EIP-1193) */
// /***********************************************************/

// let currentAccount = null;
// ethereum
//   .request({ method: 'eth_accounts' })
//   .then(handleAccountsChanged)
//   .catch((err) => {
//     // Some unexpected error.
//     // For backwards compatibility reasons, if no accounts are available,
//     // eth_accounts will return an empty array.
//     console.error(err);
//   });

// // Note that this event is emitted on page load.
// // If the array of accounts is non-empty, you're already
// // connected.
// ethereum.on('accountsChanged', handleAccountsChanged);

// // For now, 'eth_accounts' will continue to always return an array
// function handleAccountsChanged(accounts) {
//   if (accounts.length === 0) {
//     // MetaMask is locked or the user has not connected any accounts
//     console.log('Please connect to MetaMask.');
//   } else if (accounts[0] !== currentAccount) {
//     currentAccount = accounts[0];
//     // Do any other work!
//   }
// }

export default store;
