import { IERC20 } from "@/contracts/IERC20";
import { BigNumber, BigNumberish, ethers, Signer } from "ethers";
import { tokenDecimals } from "./constants";
import { Address, AssetMapping, AssetSymbol, TokenAddresses } from "./types";

export function baseTokenUnitsToDecimal(
  symbol: AssetSymbol,
  _amount: ethers.BigNumber
): string {
  let amount;
  if (typeof _amount === "string") {
    amount = ethers.BigNumber.from(_amount);
  } else {
    amount = _amount;
  }

  return ethers.utils.formatUnits(amount, tokenDecimals[symbol]);

  // const decimals = tokenDecimals[symbol];
  // const decimalDivisor = ethers.BigNumber.from(10).pow(decimals);

  // let remainder = amount.mod(decimalDivisor).toString();
  // remainder = "0".repeat(decimals - remainder.length) + remainder;

  // return parseFloat(`${amount.div(decimalDivisor)}.${remainder}`);
}

export function decimalToTokenBaseUnits(
  symbol: AssetSymbol,
  amount: string | number
) {
  if (typeof amount === "number") {
    amount = amount.toString();
  }

  return ethers.utils.parseUnits(amount, tokenDecimals[symbol]);

  // TODO: Validate input
  // const wholeValue = amount.split(".")[0];
  // const decimalValue = amount.split(".")[1] ?? "";

  // const decimals = tokenDecimals[symbol];

  // if (decimals < decimalValue.length) {
  //   // TODO: Handle better
  //   throw new Error("Too many decimal places");
  // }

  // const decimalsMultiplier = ethers.BigNumber.from(10).pow(
  //   decimals - decimalValue.length
  // );

  // const bigNumberAmount = ethers.BigNumber.from(
  //   `${wholeValue}${decimalValue}`
  // ).mul(decimalsMultiplier);

  // return bigNumberAmount;
}

export function formatRate(rate: ethers.BigNumber) {
  if (rate === undefined) {
    return "--%";
  }

  const rayMultipler = ethers.BigNumber.from(10).pow(27);

  const wholeValue = rate.div(rayMultipler.div(100));
  const decimalValue = rate.mod(rayMultipler.div(100));

  return `${wholeValue}.${"0".repeat(
    27 - 2 - decimalValue.toString().length
  )}${decimalValue}%`;
}

// TODO: This introduces extra steps for the user which is really not ideal...
export async function safeApprove(
  token: IERC20,
  signer: Signer,
  spender: Address,
  amount: BigNumberish
) {
  const owner = await signer.getAddress();
  const allowance = await token.allowance(owner, spender);
  // https://github.com/ethereum/EIPs/issues/20#issuecomment-263524729
  if (allowance.gt(0)) {
    await token.connect(signer).approve(spender, 0);
  }

  await token.connect(signer).approve(spender, amount);
}

export function getTokenAddress(
  symbol: AssetSymbol,
  contracts: TokenAddresses
) {
  let token: AssetSymbol;
  for (token in contracts) {
    if (Object.prototype.hasOwnProperty.call(contracts, token)) {
      if (token === symbol) {
        return contracts[token];
      }
    }
  }

  throw "Token not found!";
}

export function getTokenSymbol(address: Address, contracts: TokenAddresses) {
  for (const symbol in contracts) {
    if (Object.prototype.hasOwnProperty.call(contracts, symbol)) {
      if (contracts[symbol as AssetSymbol] === address) {
        return symbol;
      }
    }
  }
}

export function formatETHInRay(val: BigNumber): string {
  const rayLength = 27;

  const rayMultiplier = ethers.BigNumber.from(10).pow(rayLength);

  const wholeValue = val.div(rayMultiplier);
  const decimalValue = val.mod(rayMultiplier);

  return `${wholeValue}.${"0".repeat(
    27 - decimalValue.toString().length
  )}${decimalValue}`;
}
