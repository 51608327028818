import { IBaseOracle } from "@/contracts/IBaseOracle";
import { ethers } from "ethers";
import { tokenDecimals } from "./constants";
import { rayMul } from "./helpers";
import { AssetSymbol, Contracts } from "./types";
import OracleABI from "../contracts/IBaseOracle.json";

export class PriceFetcher {
  private oracle: IBaseOracle;
  private contracts: Contracts;

  constructor(contract: Contracts, provider: ethers.providers.Provider) {
    this.contracts = contract;
    const priceOracleAddress = this.contracts.PriceOracle;

    this.oracle = new ethers.Contract(
      priceOracleAddress,
      OracleABI,
      provider
    ) as IBaseOracle;
  }

  public async getPriceOfUnitInWeiAsRay(asset: AssetSymbol) {
    // const assetAddress = this.contracts[asset];

    // return await this.oracle.getWeiPricePerUnitInRay(assetAddress);

    // TODO: Fetch properly
    const ray = ethers.BigNumber.from(10).pow(27);

    const decimalAdjuster = ethers.BigNumber.from(10).pow(
      18 - tokenDecimals[asset]
    );

    // 1 to 1 exchange rate
    const one = ray.mul(decimalAdjuster);

    // https://coincost.net/en/currency/lp-3pool-curve
    // 1.016135
    if (asset === "3CRV") {
      // https://curve.readthedocs.io/exchange-pools.html
      // TODO: .get_virtual_price() → uint256: view
      return rayMul(
        one,
        ray.add(
          ethers.BigNumber.from(10)
            .pow(27 - 7)
            .mul(180121)
        )
      );
    }

    return one;
  }
}
