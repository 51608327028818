import store from "@/store";
import { PositionFetcher } from "@/utils/PositionFetcher";
import { Contracts } from "@/utils/types";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import { Position } from "@/utils/Position";
import { PriceFetcher } from "@/utils/PriceFetcher";

Vue.use(VueRouter);

const hydrateProps = (route: any, props: any) => {
  Object.assign(route.meta, { props });
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/farm"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    }
  },
  {
    path: "/lend",
    name: "lend",
    // route level code-splitting
    // this generates a separate chunk (lend.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lend" */ "../views/Lend.vue"),
    meta: {
      title: "Lend"
    }
  },
  {
    path: "/farm",
    name: "farm",
    // route level code-splitting
    // this generates a separate chunk (farm.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "farm" */ "../views/Farm.vue"),
    meta: {
      title: "Farm"
    }
  },
  {
    path: "/toll",
    name: "toll",
    // route level code-splitting
    // this generates a separate chunk (borrow.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "toll" */ "../views/Toll.vue"),
    meta: {
      title: "Toll"
    }
  },
  {
    path: "/admin",
    name: "admin",
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    meta: {
      title: "Admin"
    }
  },
  {
    path: "/position/:id",
    async beforeEnter(to, from, next) {
      const contracts: Contracts = store.state.contracts;
      const provider: ethers.providers.Provider = store.state.provider;
      const priceFetcher = new PriceFetcher(contracts, provider);
      const position = await new PositionFetcher(
        contracts,
        provider
      ).fetchPosition(parseInt(to.params.id), priceFetcher);
      hydrateProps(to, { position });
      to.meta.title = `Position ${to.params.id}`;
      next();
    },
    component: () =>
      import(/* webpackChunkName: "position" */ "../views/Position.vue"),
    props: route => ({
      position: route.meta.props.position
    })
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
