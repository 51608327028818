



















import { Component, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Action } from 'vuex-class';
import { ConnectionStatus } from "@/store";
import MetaMaskOnboarding from '@metamask/onboarding';
import FlatButton from "@/components/FlatButton.vue";

@Component({
  components: { AccountButton, FlatButton }
})
export default class AccountButton extends Vue {
  @State connectionStatus!: ConnectionStatus;
  @State address!: string;

  @Action connect!: ActionMethod;

  get notConnected(): boolean {
    return this.connectionStatus === ConnectionStatus.NOT_CONNECTED;
  }

  get connecting(): boolean {
    return this.connectionStatus === ConnectionStatus.CONNECTING;
  }

  get connected(): boolean {
    return this.connectionStatus === ConnectionStatus.CONNECTED;
  }

  get formattedAddress(): string {
    return `${this.address.slice(0, 6)}...${this.address.slice(-4)}`;
  }

  async handleConnectionRequest() {
    console.log("RUNNING!");

    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      // @ts-ignore
      this.$modal.show("metamask-onboarding-modal");

      return;
    }

    this.connect();
  }

  async handleMetamaskOnboarding() {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      const onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();

    //   onboardButton.innerText = 'Click here to install MetaMask!';
    //   onboardButton.onclick = () => {
    //     onboardButton.innerText = 'Onboarding in progress';
    //     onboardButton.disabled = true;
    //     onboarding.startOnboarding();
    //   };
    // } else if (accounts && accounts.length > 0) {
    //   onboardButton.innerText = 'Connected';
    //   onboardButton.disabled = true;
    //   onboarding.stopOnboarding();
    // } else {
    //   onboardButton.innerText = 'Connect';
    //   onboardButton.onclick = async () => {
    //     await window.ethereum.request({
    //       method: 'eth_requestAccounts',
    //     });
    //   };
    }
  }
}
